<template>
  <div>
    <v-spacer></v-spacer>
    <v-card class="mt-3">
      <v-card-title>
        <v-text-field
          v-model="search"
          placeholder="Search for a Fica Bundle ficaProduct by name..."
          outlined
          clearable
          counter
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="ficaProducts.records"
          :loading="isLoading"
          :search="search"
          transition="scale-transition"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title
                ><h4 class="text-md-h4 primary--text">
                  Fica Bundle Products
                </h4></v-toolbar-title
              >

              <v-btn
                class="mt-2"
                color="primary"
                large
                @click="$router.push('/admin/ficaproduct0/edit')"
              >
                <h1>New FicaProduct</h1>
                <v-icon right>
                  mdi-plus
                </v-icon>
              </v-btn>
              <v-spacer />
              <download-excel
                :data="ficaProducts.records"
                :fields="fields"
                worksheet="FicaBundles"
                name="MedsafuGroupCRMFicaBundleList.xlsx"
              >
                <v-btn color="#0a2752" large dark>
                  <h1>Download</h1>
                </v-btn>
              </download-excel>
            </v-toolbar>
          </template>
          <template v-slot:item.serial="{ item }">
            <h4 color="primary">
              <router-link :to="'/admin/ficaproduct' + item.id">{{
                item.serial
              }}</router-link>
            </h4>
          </template>
          <template v-slot:item.type="{ item }">
            <v-chip label dark>{{ item.type.toUpperCase() }}</v-chip>
          </template>
          <template v-slot:item.createdAt="{ item }">
            <v-row>
              <h3>Created At:</h3>
              <h3 color="primary">{{ item.createdAt | getFormattedDate }}</h3>
            </v-row>
            <v-row>
              <h3 color="primary">Updated At:</h3>
              <h3 color="primary">{{ item.updatedAt | getFormattedDate }}</h3>
            </v-row>
          </template>
          <template v-slot:item.price="{ item }">
            <h3 color="primary">R {{ item.price ? item.price : "0" }}</h3>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              fab
              small
              dark
              class="mr-2"
              @click="editItem(item)"
              color="primary"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              fab
              small
              dark
              class="mr-2"
              @click="deleteItem(item)"
              color="error"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  components: {},
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Description", value: "description" },
        { text: "Category", value: "category" },
        { text: "Date", value: "createdAt" },
        { text: "Actions", align: "right", sortable: false, value: "actions" },
      ],
      search: "",
      ficaProducts: [],
      dialog: false,
      totalRecords: 0,
      isLoading: false,
      fields: {
        "Fica Bundle Name": "name",
        "Fica Bundle Description": "description",
        "Fica Bundle Category": "category",
        "Created At": "createdAt",
      },
    };
  },

  apollo: {
    ficaProducts: {
      query: gql`
        query FicaProducts($limit: Int!, $skip: Int!, $query: JSON!) {
          ficaProducts(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              name
              description
              category
              documents {
                id
              }
              createdAt
              updatedAt
            }
            count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: {},
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },

  methods: {
    editItem(item) {
      this.$router.push(`/admin/ficaproduct/${item.id}/edit`);
    },
    deleteItem(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#b3a369",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          try {
            const response = await this.$apollo.mutate({
              mutation: gql`
                mutation($id: ID!) {
                  deleteFicaProduct(id: $id) {
                    id
                    deleted
                  }
                }
              `,
              variables: {
                id: item.id,
              },
            });

            if (response) {
              this.$swal(
                "Success!",
                "FicaProduct was successfully deleted",
                "success"
              );
              this.$router.go(this.$route.currentRoute);
            }
          } catch (e) {
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
